.pagination__container {
    display: flex;
    align-items: center;
    justify-content: center;

    .pagination {
        align-items: center;
        margin-block-start: 2rem;
        display: flex;

        &__page {
            width: 33px;
            height: 33px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            margin-inline: 8px;
        }

        .break {
            margin-inline: 15px;
        }

        .selected {
            background-color: $blue_100;
            color: #fff;
        }

        .previous,
        .next {
            margin-inline: 1.8rem;

            &:lang(ar) {
                transform: scaleX(-1);
            }
        }

        .disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        .left,
        .right {
            width: 30px;
            height: 30px;
        }

        .left {
            transform: rotate(180deg);
        }
    }
}