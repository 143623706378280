@import '../../resources/style/mixins';

.loading {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.badges {
  display: flex;
  align-items: center;
  color: #333;

  span {
    border-radius: 7px;
    margin-inline: 8px;
    background-color: #fff;
    @include display-flex(row, center, center);
    padding: 0.05em 0.4em;

    p {
      color: #176eb5 !important;
    }

    svg {
      margin-inline: 4px;
      width: 25px;
    }
  }
}