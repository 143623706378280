@import "../../resources/style/variables";
@import "../../resources/style/mixins";

:root {
  --menu--size: 60vw
}

.menu {
  margin-inline: 1.5rem auto;
  position: relative;

  @media (max-width: 1450px) {
    position: unset;
  }

  @media (max-width: 450px) {
    margin-inline: 0.5rem auto;
  }

  &__btn {
    display: inline-block;
    border: 0;
    border-radius: 0.25rem;
    padding: 0.5rem 0.75rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    background-color: #0593ff;
    display: flex;
    align-items: center;
    gap: 0.5rem;



    &__img {
      width: 14px;
    }

    @media (max-width: 450px) {
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;

      &__img {
        width: 15px;
      }
    }
  }

  &__container {
    position: absolute;
    // background-color: #fff;
    left: 0;
    top: 150%;
    display: flex;
    align-items: stretch;
    border-radius: 0.5rem;
    overflow: hidden;

    &:lang(ar) {
      left: auto;
      right: 0;
    }

    @media (max-width: 1450px) {
      left: calc((100% - 85vw) / 2);

      &:lang(ar) {
        right: calc((100% - 85vw) / 2);
        left: auto;
      }
    }

    @media (max-width: 1000px) {
      left: calc((100% - 90vw) / 2);

      &:lang(ar) {
        right: calc((100% - 90vw) / 2);
        left: auto;
      }
    }

    @media (max-width: 950px) {
      left: 0;
      width: 100%;
      overflow: visible;

      &:lang(ar) {
        right: 0;
        left: auto;
      }
    }

    @media (max-width: 750px) {
      width: 110%;
      left: -5%;

      &:lang(ar) {
        width: 110%;
        left: auto;
        right: -5%;
      }
    }

    &__submenu {
      width: 15vw;
      background-color: #efefef;

      @media (max-width: 1450px) {
        width: 25vw;
      }

      @media (max-width: 950px) {
        width: 100%;
        position: absolute;
        border-radius: 0.5rem;
        overflow: hidden;
        z-index: 100
      }

    }

    &__modules {
      transition: all 350ms ease-in-out;
      width: 27vw;
      box-shadow: 0 2px 2px 2px #00000009;
      background-color: #fff;
      padding-bottom: 2rem;

      @media (max-width: 1450px) {
        width: 35vw;
      }

      @media (max-width: 1000px) {
        width: 40vw;
      }

      @media (max-width: 950px) {
        width: 100%;
        position: absolute;
        border-radius: 0.5rem;
        overflow: hidden;
        z-index: 100
      }

      &__header {
        display: flex;
        gap: 1rem;
        padding-block: 1rem;
        padding-inline: 2rem 0.5rem;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #F5F5F5;

        @media (max-width: 1000px) {
          padding-inline: 1rem;
        }

        &__content {
          display: flex;
          flex-direction: column;
          width: 55%;
          gap: 1rem;
        }

        &__title {
          font-size: 1rem;
          font-weight: 700;
          color: #176EB5;
          margin-bottom: 0;
        }

        &__text {
          font-size: 0.875rem;
          color: #4E4E4E;
        }

        &__link {
          width: fit-content;
          padding: 1rem;
          border: 1px solid #0593FF;
          border-radius: 0.5rem;
          font-size: 0.75rem;
          letter-spacing: 1px;
        }

        &__img {
          width: 35%;
          border: 1px solid #000;
          border-radius: 0.5rem;

          @media (max-width: 1600px) {
            width: 40%;
          }

          @media (max-width: 950px) {
            width: 25%;
          }

          @media (max-width: 700px) {
            width: 30%;
          }

          @media (max-width: 600px) {
            width: 35%;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;

        &__title {
          font-size: 1rem;
          color: #176EB5;
          font-weight: 700;
          padding-block: 1rem 0.5rem;
          padding-inline: 2rem 0.5rem;
          margin-bottom: 0;

          @media (max-width: 1000px) {
            padding-inline: 1rem;
          }
        }

        &__link {
          padding: 0.5rem;
          padding-left: 2.5rem;
          font-size: 1rem;
          color: #176EB5;
          cursor: pointer;

          &:hover {
            background-color: #F8F8F8;
          }

          @media (max-width: 1000px) {
            padding-left: 1.5rem;
          }

          @media (max-width: 950px) {
            &:hover {
              background-color: #F8F8F8;
            }
          }

          &.active {
            background-color: #F8F8F8;
          }
        }
      }
    }

    &__weeks {
      transition: all 350ms ease-in-out;
      width: 18vw;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-block: 2rem;
      padding-inline: 0.5rem 1.5rem;

      @media (max-width: 1450px) {
        width: 25vw;
      }

      @media (max-width: 1000px) {
        padding-block: 1rem;
        padding-inline: 1rem;
      }

      @media (max-width: 950px) {
        width: 100%;
        position: absolute;
        border-radius: 0.5rem;
        overflow: hidden;
        z-index: 100
      }

      &__title {
        font-size: 1rem;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
        align-items: center;
        color: #176EB5;
        margin-bottom: 0;

        .view__all {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-size: 0.875rem;
          font-weight: 500;
          cursor: pointer;

          &:lang(ar) {
            img {
              transform: rotate(180deg);
            }
          }
        }
      }

      &__card {
        background-color: #F8F8F8;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        gap: 0.5rem;
        cursor: pointer;

        &:hover {
          background-color: #F5F5F5;
        }

        .week {
          font-size: 1rem;
          color: #176EB5;

          &__title {
            font-size: 1rem;
            color: #176EB5;
            font-weight: 600;
          }
        }

        &:lang(ar) {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__link {
    width: 100%;
    color: #1c7cd2;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
    background-color: #F8F8F8;
    border: 0;

    &:not(:last-of-type) {
      border-bottom: 1px solid #fff !important;

    }






    &:lang(ar) {
      img {
        transform: rotate(180deg);
      }
    }

    &:hover {
      background-image: url(../../resources/assets/images/green-gradient@2x.png);
      background-repeat: no-repeat;
      background-position: center right;
      background-size: cover;
      color: #fff;

      img {
        content: url(../../resources/assets/icons/w-left-arrow.svg);
      }
    }

    // @media (max-width: 950px) {
    // }
    &.active {
      background-image: url(../../resources/assets/images/green-gradient@2x.png);
      background-repeat: no-repeat;
      background-position: center right;
      background-size: cover;
      color: #fff;

      img {
        content: url(../../resources/assets/icons/w-left-arrow.svg);
      }
    }
  }
}

.back__btn {
  display: none;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  // padding: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #176EB5;
  background-color: transparent;
  border: 0;

  img {
    width: 15px;

    &:lang(ar) {
      transform: rotate(180deg);
    }
  }

  @media (max-width: 950px) {
    &:nth-of-type(1) {
      padding: 1rem;
      padding-bottom: 0;
    }

    &:nth-of-type(2) {
      padding-top: 0;
    }

    display: flex;
  }
}

.hide {
  display: none;
}

.hide__special {
  visibility: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000055;
  z-index: 1;
}