.main-navContainer>.nav {
  .nav-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    background-color: #f0f0f0 !important;
    border-radius: 0 !important;
    color: #004377;
    flex: 1;

    &:not(:last-of-type) {
      border-inline-end: 4px solid white;
    }

    @media screen and (max-width:991.98px) {
      padding: 0
    }



    .nav-btn-img-container {
      border-bottom-right-radius: 50%;
      border-top-right-radius: 50%;
      margin: 20px;
      width: 80px;
      height: 80px;

      @media screen and (min-width:992px) {
        display: none;
      }

      @media screen and (max-width:991.98px) {
        transform: rotate(90deg);
      }

      img {
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
        margin-left: 20px;

        @media screen and (max-width:991.98px) {
          transform: rotate(-90deg);
          width: auto;
          height: auto;

        }

        @media screen and (max-width:1199.98px) {
          margin-left: 10px;
        }
      }
    }

    h2 {
      text-align: start;
    }

    p {
      text-align: start;
    }
  }

  .active {
    color: white;

    &:nth-of-type(1) {
      background-color: #0cb2c2 !important;
    }

    &:nth-of-type(2) {
      background-color: #6a6fb1 !important;
    }

    &:nth-of-type(3) {
      background-color: #9853a3 !important;
    }

    &:nth-of-type(4) {
      background-color: #03ca8e !important;
    }

    img {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }
  }
}

.main-navContainer>.tab-content {
  min-height: 35rem;
  background-color: white;
  border-end-start-radius: 25px;
  border-end-end-radius: 25px;


  @media screen and (max-width:991.98px) {
    border-radius: 0;

    margin-right: 0;
    height: auto;
    padding-bottom: 4.5rem
  }

  .tab-pane {
    height: 100%;
  }


}


.nav-pills {
  border-start-start-radius: 25px;
  border-start-end-radius: 25px;
  border: 5px solid white;
  border-bottom: 0;
  overflow: hidden;
}

.tab_text {
  @media screen and (max-width:991.98px) {
    display: none;
  }

}



.nav-tabs {
  border-color: #176EB5 !important;

  .nav-link {
    opacity: 0.5;

    &.active {
      background-color: #176EB5 !important;
      color: #fff !important;
      border: 0;
      opacity: 1;
    }
  }
}