.form_container {
  margin: 35px 0;
  background-color: #f7f7f7;
  width: 100%;
  padding: 50px;
  border-radius: 25px;

  @media only screen and (max-width: 991.9px) {
    padding: 25px 0px;
  }

  a {
    margin-bottom: 1rem;
    height: 45px;
  }
}

.column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 991.9px) {
    padding-inline: 25px;
  }
  @media only screen and (max-width: 575.9px) {
    flex-direction: column-reverse;
    align-items: stretch;

  }
  h5 {
    color: #176eb5;
  }
}

.form {
  border-radius: 25px;
  background-color: #ffffff;
  border: 2px solid #C5C5C2;
  padding: 30px 0;

  @media only screen and (max-width: 991.9px) {
    padding: 10px 0;
    margin-inline: 5px;
    border-width: 1px ;
    border-radius: 5px;
  }

  .NameInput {
    width: 95%;
    margin: 15px auto;

    input {
      border: 1px solid #c3bedd;
      padding: 5px 15px;
      border-radius: 6px;
      width: 45%;
      height: 48px;
      @media only screen and (max-width: 575.9px) {
        width: 100%;
      }

      &:focus-visible {
        outline: none;
      }
    }
  }
}

.inner_header {
  background-color: #e4e4e4;
  width: 95%;
  margin: 2rem auto;
  padding-block: 15px;
  padding-inline: 40px;
  @media only screen and (max-width: 575.9px) {
    padding-inline: 18px;
  }
}

.input-item {
  width: 95%;
  margin: 0px auto;
  padding-block: 0px;
  padding-inline: 40px;
  input {
    margin-right: 15px;
  }
}
