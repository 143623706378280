$blue_50: #0593FF;
$blue_60: #1487EB;
$blue_100: #176EB5;
$blue_105: #1277D1;
$blue_110: #185A76;
$blue_120: #16507E;
$blue_200: #004377;



// $grenish
$grenish_10: #3F9CBB;

// $pink
$pink_10: #DCA5CC;
$pink_100: #CD229A;

//$purple
$purple_70: #9995C7;
$purple_80: #6A6FB1;
$purple_100: #9853A3;

// $green
$grenish_5: #B3DEE1;

$green_10: #BBD861;
$green_20: #66C08C;
$green_30: #54D459;
$green_50: #03CA8E;
$green_100: #208B30;

// $orange
$orange_10: #FAA880;
$orange_50: #E5B65E;


// $gray

$gray_5: #F7F7F7;
$gray-6: #F0F0F0;
$gray_7: #DCD5D5;
$gray_10: #3B3B3B28;
$gray_15: #EFEFEF;
$gray_20: #F5F5F5;
$gray_25: #F8F8F8;
$gray_30: #E6E6E6;

$gray_50: #E4E4E4;
$gray_100: #C5C5C2;


// $black
$black_10: #333333