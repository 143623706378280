.f-48 {
  font-size: 48px;

  @media screen and (max-width: 767.98px) {
    font-size: 30px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024.98px) {
    font-size: 36px;
  }

  @media screen and (min-width: 1025px) and (max-width: 1199.98px) {
    font-size: 40px;
  }
}

.f-42 {
  font-size: 42px;

  @media screen and (max-width: 767.98px) {
    font-size: 26px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024.98px) {
    font-size: 34px;
  }

  @media screen and (min-width: 1025px) and (max-width: 1199.98px) {
    font-size: 38px;
  }
}

.f-38 {
  font-size: 38px;

  @media screen and (max-width: 767.98px) {
    font-size: 28px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024.98px) {
    font-size: 32px;
  }

  @media screen and (min-width: 1025px) and (max-width: 1199.98px) {
    font-size: 36px;
  }
}

.f-32 {
  font-size: 32px;

  @media screen and (max-width: 767.98px) {
    font-size: 22px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024.98px) {
    font-size: 26px;
  }

  @media screen and (min-width: 1025px) and (max-width: 1199.98px) {
    font-size: 28px;
  }
}

.f-28 {
  font-size: 28px;

  @media screen and (max-width: 767.98px) {
    font-size: 18px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024.98px) {
    font-size: 21px;
  }

  @media screen and (min-width: 1025px) and (max-width: 1199.98px) {
    font-size: 24px;
  }
}

.f-23 {
  font-size: 23px;

  @media screen and (max-width: 767.98px) {
    font-size: 18px;
  }

  @media screen and (min-width: 768px) and (max-width: 1199.98px) {
    font-size: 21px;
  }


}

.f-20 {
  font-size: 20px;

  @media screen and (max-width: 767.98px) {
    font-size: 16px;
  }

  @media screen and (min-width: 768px) and (max-width: 1199.98px) {
    font-size: 18px;
  }

}

.f-18 {
  font-size: 18px;

  @media screen and (max-width: 767.98px) {
    font-size: 14px;
  }

  @media screen and (min-width: 768px) and (max-width: 1199.98px) {
    font-size: 16px;
  }
}

.f-16 {
  font-size: 16px;

  @media screen and (max-width: 767.98px) {
    font-size: 14px;
  }
  @media screen and (min-width:768px) and (max-width:991.98px){
    font-size: 15px;
  }
}
.f-14{
  font-size: 14px;

  @media screen and (max-width: 767.98px) {
    font-size: 13px;
  }
  @media screen and (min-width:768px) and (max-width:1199.98px){
    font-size: 13px;
  }
}

.f-12 {
  font-size: 12px;

  @media screen and (max-width: 767.98px) {
    font-size: 10px;
  }
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.meduim {
  font-weight: 500;
}

.semi_bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}