.guidance-content {
  h1 {
    width: fit-content;
    margin: 0 auto;
  }

  .accordion {
    margin-top: 3rem;
  }
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
