@import "./fonts.scss";
@import "./home-tabs.scss";
@import "./accordion.scss";
@import "./variables";
@import "./channel.scss";
@import "./pagination.scss";

*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  color: $black_10;
  overflow-x: hidden;

  body {
    overflow-x: hidden;


    p {
      line-height: 1.6;
    }
  }
}

html[lang="ar"] {
  * {
    font-family: "Cairo", sans-serif;
  }
}

html[lang="en"] {
  * {
    font-family: "Raleway", sans-serif;
  }
}

.root {
  position: relative;
}

.p-inline {
  padding-inline: 5rem;

  @media screen and (max-width: 767.98px) {
    padding-inline: 1.5rem;
  }

  @media screen and (min-width: 768px) and (max-width: 991.98px) {
    padding-inline: 2rem;
  }

  @media screen and(min-width:992px) and (max-width: 1440.98px) {
    padding-inline: 3rem;
  }

}

.p-block {
  padding-block: 5.5rem;

  @media screen and (max-width: 767.98px) {
    padding-block: 2.5rem;
  }

  @media screen and(min-width:992px) and (max-width: 1199.98px) {
    padding-block: 3rem;
  }
}

.m-top {
  margin-top: 5rem;

  @media screen and (max-width: 767.98px) {
    margin-top: 2rem;
  }

  @media screen and (min-width: 768px) and (max-width: 991.98px) {
    margin-top: 3rem;
  }

  @media screen and(min-width:992px) and (max-width: 1199.98px) {
    margin-top: 4rem;
  }
}

.m-bottom {
  margin-bottom: 5rem;

  @media screen and (max-width: 767.98px) {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: 768px) and (max-width: 991.98px) {
    margin-bottom: 3rem;
  }
}

.m-block {
  margin-block: 6rem;

  @media screen and (max-width: 767.98px) {
    margin-block: 2rem;
  }

  @media screen and (min-width: 768px) and (max-width: 991.98px) {
    margin-block: 3rem;
  }
}

a {
  text-decoration: none !important;
}

p {
  margin-bottom: 0 !important;
}

ul {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

li {
  list-style-type: none;
}

.rotate {
  transform: rotatez(-35deg) translateX(-10px) translateY(-13px);
}

.px-0 {
  padding-inline: 0;
}

.margin-bottom-35 {
  margin-bottom: 35px;
}

.width-80 {
  width: 80%;
}

.active {
  font-weight: 600;
}

.text-blue {
  color: $blue_100;
}

.m-inline-1 {
  margin-inline: 1rem;
}

.ratio {
  width: 100% !important;
}

.navbar-brand {
  margin: 0 !important;

  img {
    width: 250px;

    @media screen and (max-width: 991.98px) {
      width: 200px;
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    // max-width: 900px !important;
    margin: 1.75rem auto;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 700px !important;
  }
}

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 950px !important;
  }
}

.modal-dialog {
  position: relative;

  .btn-close {
    width: 10px;
    height: 20px;
    background-image: url("../assets/icons/close-copy.svg");
    background-size: contain;
    position: relative;
    left: 0;
    top: -12px;
    opacity: 1;
  }

  .modal-content {
    border-radius: 25px !important;
    border: 0 !important;

    background-color: transparent !important;

    .modal-header {
      border: 0;
    }

    video {
      border-radius: 25px;
    }
  }
}

#v-pills-tab {
  @media screen and (max-width: 991.98px) {
    flex-direction: row !important;

    button {
      width: calc(100% / 4);
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 0 !important;
      padding-top: 0 !important;
      overflow: hidden;

      &:nth-of-type(1) {
        border-top-left-radius: 25px !important;
      }

      &:nth-of-type(4) {
        border-top-right-radius: 25px !important;
      }
    }


  }
}

html[lang="ar"] {
  @media screen and (max-width: 991.98px) {
    #v-pills-tab {
      button {
        &:nth-of-type(1) {
          border-top-right-radius: 25px !important;
          border-top-left-radius: 0 !important;
        }

        &:nth-of-type(4) {
          border-top-left-radius: 25px !important;
          border-top-right-radius: 0 !important;
        }
      }
    }
  }
}



.accordion-body {
  @media screen and (max-width: 767.98px) {
    padding: 0 !important;
  }
}

.navbar-nav {
  @media screen and (max-width: 991.98px) {
    a {
      color: black;
    }
  }
}

.navbar-toggler {
  border: 0 !important;
  display: flex;
  top: 1.5rem;
  left: 1.5rem;
  z-index: 12;
  background-repeat: no-repeat;

  &.collapsed {
    .navbar-toggler-icon {
      background-image: url("../assets/icons/bars.svg") !important;
    }

    &.dark {
      .navbar-toggler-icon {
        background-image: url("../assets/icons/bars-black.svg") !important;
      }
    }
  }

  .navbar-toggler-icon {
    background-image: url("../assets/icons/close-x.svg") !important;
    width: 25px;
    height: 25px;
  }

  &:focus,
  &:focus-visible &:focus:not(:focus-visible) {
    outline: none !important;
    box-shadow: none !important;
  }
}

.header--overlay {
  position: fixed !important;
  z-index: 18 !important;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all ease-in-out;

  &.active {
    visibility: visible;
    opacity: 1;
  }

}

.overlay {
  position: absolute;
  width: 100% !important;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.accordion-body {
  @media screen and (max-width: 1199.98px) {
    padding: 0 !important;
  }
}

.show>.btn-primary.dropdown-toggle {
  color: $blue_100 ;
  background-color: white !important;
  border-color: white !important;
}

.dropdown-item {
  white-space: pre-wrap !important;
}

hr {
  margin: 0.5rem 0
}