.hero {
    background-image: url('../../resources/assets/images/purble-bg.png');
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: 100% -180px;

    background-size: cover;
    color: white;

    @media screen and (max-width:767.98px) {
        background-position: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &:before {
        content: '';
        position: absolute;
    }

    nav {
        margin-block-start: 4rem;

        ol {
            background-color: transparent;

            a {
                color: white;
            }

        }
    }

    h2 {
        padding-top: 1rem;
    }



}

.breadcrumb {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-top: 3rem;

    &__link {
        color: #fff;

        &:hover {
            color: #eee;
        }
    }

    &__text {
        color: #fff;
    }

    &__arrow {
        &:lang(ar) {
            transform: rotate(180deg);
        }
    }
}