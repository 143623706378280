@import "../../resources/style/variables";
@import "../../resources/style/mixins";

.hero {
  @media screen and (min-width: 1200px) {
    min-height: 95vh;
  }

  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  @include display-flex(column, center, center); // padding-bottom: 18rem;
  padding-top: 10rem;
  padding-bottom: 15rem;

  .column1 {
    background-color: #fff;
    @include display-flex(row, space-between, center);
    position: absolute;
    left: 0;
    top: 6rem;
    width: 100%;
    z-index: 1;

    @media screen and (max-width: 991.98px) {
      top: 7rem;
      // background-color: transparent;
      flex-direction: column;
      align-items: flex-start;
    }

    h1 {
      margin: 0;
      margin-block: 10px;
      color: #004377;

    }

    div.cust {
      display: flex;
      align-items: center;

      .dropdown {
        .dropdownBtn {
          &:focus {
            box-shadow: 0 0 0 0;
          }

          padding: 10px 20px;
          margin: 10px 0;
          background-color: #e4e4e4;
          color: #707070;
          border: 0;

          +div {
            text-align: start;
          }
        }
      }

      p {
        margin-inline-start: 20px;

        @media screen and (max-width:767.98px) {
          display: none
        }
      }
    }
  }

  .main {
    margin: 0;
    // @include display-flex(row, space-between, center);

    margin-block-end: 3rem;
    margin-block-start: 4rem;




    * {
      color: #fff;
    }


    .row1 {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        line-height: 1.6;
      }


      ul {
        li {
          display: flex;
          flex-direction: row;
          margin-bottom: 25px;

          p {
            margin-inline: 10px;
          }
        }
      }
    }

    .row2 {
      @include display-flex(row, center, center);
      position: relative;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        border-radius: 25px;

        &.shapes {
          position: absolute;
          width: 180px;
          height: 180px;
          right: -50px;
          top: -50px;

          @media screen and (min-width:992px) and (max-width:1199.98px) {
            width: 140px;
            height: 140px;
            top: 0;
          }

          @media screen and (max-width: 991.98px) {
            display: none;
          }

          &:last-of-type {
            right: 80%;
            top: 70%;

            @media screen and (min-width:992px) and (max-width:1199.98px) {
              top: 65%;
            }
          }
        }
      }
    }
  }
}

.translate_en {
  transform: translateX(25px);

  @media screen and (max-width: 991.98px) {
    transform: translateX(0);
    margin-top: 2rem;
  }
}

.translate_ar {
  transform: translateX(-25px);

  @media screen and (max-width: 991.98px) {
    transform: translateX(0);
    margin-top: 2rem;
  }
}

.breadcrumb {
  width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-top: 3rem;
    &__link {
        color: #fff;
        &:hover {
            color: #eee;
        }
    }
    &__text {
        color: #fff;
    }
    &__arrow {
        &:lang(ar) {
            transform: rotate(180deg);
        }
    }
}