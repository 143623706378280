@import "../../resources/style/variables";
@import "../../resources/style/mixins";


$left: 5rem;



.navBar__nav {
  flex-direction: row;
  margin-inline-start: auto;

  @media screen and (max-width: 991.98px) {

    flex-direction: column !important;
    background-color: white;
    position: fixed;
    left: 50%;
    width: 90%;
    top: 0;
    align-items: flex-start !important;
    padding: 2rem;
    display: none;
    top: 5.5rem;
    border-radius: 10px;

    transform: translateX(-50%);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;

    >span,
    a {
      width: 80%;
    }
  }
}

.show {
  @media screen and (max-width: 991.98px) {
    display: flex;
    gap: 0.5rem;
  }
}

.fixed {
  position: fixed;
  left: $left;
  top: 1rem;
  width: calc(100% - 2*$left);
  height: 4rem;
  padding-inline: 1.5rem;
  z-index: 1000;
  @include display-flex(row, center, center);
  background-color: #fff;
  border-radius: 9px;

  @media (max-width: 1440px) {
    width: 95%;
    left: 2.5%;
  }


  .nav_item {
    border: none;
    background-color: transparent;
    margin-left: 1rem;
    color: $blue_105;
  }

}

.userLogo {

  color: #fff;
  font-size: 25px;
  font-weight: 500;

  border-radius: 25px;

  >div>div {
    text-align: start !important;

  }



  button {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;

    &:after {
      display: none;
    }

    &:hover,
    &:active {
      background-color: transparent;
    }

    &:focus {
      outline: none;
      box-shadow: none !important;
    }



  }


  // background-color: #0593ff;
  svg {
    height: 40px;
    width: 40px;
    ;
  }
}

.navbar_logo {
  display: flex;
  z-index: 1;
  transform: translateY(3px);
  width: 200px;

  img {
    width: 100%;
  }

  @media screen and (max-width: 991.98px) {
    width: 200px;
  }

  @media (max-width: 450px) {
    width: 150px;
  }
}

.wrapper {
  @include display-flex(row, flex-start, center);
  position: relative;

}

.toggle_btn {
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
}

.lang_btn {
  font-family: "Cairo", sans-serif !important;
}

html[lang="ar"] {
  .toggle_btn {
    left: 0;
    right: auto;
  }

  .lang_btn {
    font-family: "Raleway", sans-serif !important;
  }
}