@import "../../resources/style/variables";
@import "../../resources/style/mixins";

.channel {
    height: 380px;
    margin: 8px;
    width: calc(100% / 4 - 16px);
    border-radius: 25px;
    overflow: hidden;

    @media screen and (max-width:576.98px) {
        width: 100%
    }

    @media screen and (min-width:577px) and (max-width:991.98px) {
        width: calc(100% / 2 - 16px)
    }

    @media screen and (min-width:992px) and (max-width:1199.98px) {
        width: calc(100% / 3 - 16px)
    }

    &__top {
        height: 77%;
        position: relative;
        object-fit: cover;
        display: flex;


        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }

    &__footer {
        height: 27%;
        text-align: start;
        background-color: #fff;
        padding: 8px 1rem;

        span {
            text-transform: uppercase;
            color: $blue_100;
            display: inline-block;
        }

        h3 {
            color: #333333
        }
    }
}

.play {
    background-color: rgba(255, 255, 255, 0.5);
    width: 65px;
    height: 65px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 38%;
    border-radius: 50px;
    z-index: 12;
    @include display-flex(row, center, center);

    @media screen and (max-width: 991.98) {
        width: 40px !important;
        height: 40px;
    }

    svg {
        fill: #ffff;
        width: 30px;
        height: 30px;
    }
}