@import '../../../resources/style/mixins';
@import '../../../resources/style/variables';

.aboutSection {
  @include display-flex(column, center, center);
  background-color: white;

  .title {
    margin-block: 3rem !important;

    h1 {
      -webkit-background-clip: text;
      background-clip: text;
      background-image: url('../../../resources/assets/images/green-gradient@2x.png');
      color: transparent;

    }

    >p {
      margin-block-start: 2rem;
    }

    margin:0 auto;
    text-align: center;
    width:55%;

    @media screen and (max-width:767.98px) {
      width: 100%;
    }
  }

  .circleWrapper {
    @include display-flex(row, flex-start, center);
    z-index: 1;

    @media screen and (max-width:1199.98px) {
      flex-wrap: wrap;
      justify-content: center;
    }

  }

}