@import '../../../../resources/style/variables';
@import '../../../../resources/style/mixins';


.card {
    margin-block: 1rem;
    @include display-flex(row, flex-start, center);

    @media screen and (max-width:767.98px) {
        flex-direction: column;
    }

    .day {
        @include display-flex(row, flex-start, center);
        height: 100%;
        width: 200px;

        @media screen and (min-width:768px) and (max-width:1199.98px) {
            width: 150px;
        }
        @media screen and (max-width:767.98px){
            width:95%
        }

        span {
            margin-inline: 6px;
        }
    }

    .content {
        width: calc(100% - 230px);
        @include display-flex(row, space-around, flex-start);

        @media screen and (min-width:768px) and (max-width:1199.98px) {
            width: calc(100% - 150px);
        }

        @media screen and (max-width:767.98px) {
            width: 100%;
            margin-top: 16px;
            flex-wrap: wrap;
        }
    }
}

hr {
    background-color: $gray-7;
}