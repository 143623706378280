@import '../../resources/style/mixins';

.relative {
    position: relative
}

.play {
    background-color: rgba(255, 255, 255, 0.5);
    width: 75px !important;
    height: 75px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;
    border-radius: 50px;
    z-index: 1;
    @include display-flex(row, center, center);

    svg {
        fill: #ffff;
        width: 30px;
        height: 30px;

    }

}

.custom {
    border-radius: 25px;
}
