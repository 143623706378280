@import '../../../../resources/style/mixins';
@import '../../../../resources/style/variables';

.resources {
    margin-block: 3.5rem;

    >div {
        &:first-of-type {
            @include display-flex(column, center, center);

            p,
            h3 {
                text-align: center;
                margin-bottom: 2rem !important;
            }

            p {
                width: 45%;
                @media screen and (max-width:575.98px){
                    width:100%
                }
                @media screen and (min-width:576px) and (max-width:767.98px){
                    width:95%
                }
            }

        }

    }

}

.resources_wrapper{
    @include display-flex(row, flex-start, center);
    flex-wrap: wrap;
    flex-wrap: wrap;
    @media screen and (max-width:767.98px){
        flex-direction: column;
    }
}