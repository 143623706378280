ul.vertical_list {
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: row;

  li {
    width: 33%;
    display: flex;
    flex-direction: row;



    svg {
      margin-right: 15px;
    }

  }
}

ul.pointed_list {
  li {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    padding-inline-start: 2rem;
    position: relative;
    margin-inline-start: 15px;

    &:before {
      position: absolute;
      content: '';
      display: flex;
      margin-inline: 12px;
      padding: 5px;
      background-image: url('../../../resources/assets/icons/check-mark.svg');
      width: 20px;
      height: 20px;
      inset-inline-start: -1rem;
      background-repeat: no-repeat;
      background-size: contain;

      @media screen and (max-width:991.98px) {
        margin-inline: 8px;
      }
    }
  }


}