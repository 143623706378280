@import "../../resources/style/mixins";
@import "../../resources/style/variables";

.card {
	border-radius: 25px;
	background-color: $gray-6;
	overflow: hidden;
	position: relative;
	box-shadow: 0px 13px 16px #3b3b3b28;
	cursor: pointer;
	display: inline-block;
	width: 100%;
	border: 0;
	padding: 0;
}

video {
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.custom_overlay {
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.relative {
	position: relative;
}

.play {
	background-color: rgba(255, 255, 255, 0.5);
	width: 65px;
	height: 65px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 38%;
	border-radius: 50px;
	z-index: 1;
	@include display-flex(row, center, center);

	@media screen and (max-width: 991.98) {
		width: 40px !important;
		height: 40px;
	}

	svg {
		fill: #ffff;
		width: 30px;
		height: 30px;
	}
}

.download {
	background-color: white;
	border-radius: 7px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-inline: 10px;
	height: 35px;
}

.card_media {
	width: 160px;
	border-radius: 15px;
	overflow: hidden;

	@media screen and (max-width: 991.98px) {
		width: 100px;
	}

	img {
		object-fit: cover;
		border-radius: 15px;
	}
}

.card_text {
	width: calc(100% - 170px);

	@media screen and (max-width: 991.98px) {
		width: calc(100% - 110px);
	}
}

.resource_content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	text-align: start;
	padding: 0.8rem 1rem;

	span {
		color: $blue_100;
		text-transform: capitalize;
	}
}