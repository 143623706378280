@import "../../resources/style/variables";

.wrapper {
  padding-inline: 0;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  margin-top: 4rem;
  padding: 1.5rem;

  img {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;

    +div {
      align-items: center;
      justify-content: space-between;
      width: 90%;
      margin: 0 auto;
      height: 100%;
      color: white;

      >div {
        display: flex;
        flex-direction: column;
      }


      h3 {
        margin-bottom: 1rem;
      }
    }
  }

  button {
    background-color: white;
    padding-inline: 15px;
    height: 50px;
    box-shadow: 0px 3px 6px $gray_10;
    color: $blue_100;
    border: 0;
    margin-top: 2rem;
    width: fit-content;
  }

  svg {
    // width: 270px;
    transform: translate(2rem, 4.1rem);

    @media screen and (max-width: 991.98px) {
      display: none;
    }
  }
}