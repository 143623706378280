@import "../../resources//style/mixins";

.column2 {
  border-radius: 25px;
  padding: 30px 62px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width:767.98px) {
    padding: 2rem;
  }

  h1 {
    margin: 0;
    margin-block: 10px;
  }

  ul {
    width: 65%;
    margin: 0;

    @media screen and (max-width:991.98px) {
      width: 100%;
      margin-bottom: 2rem
    }

    h3 {
      margin-bottom: 25px;
    }

    li {
      margin-block: 10px;
      @include display-flex(row, center, center);

      span {
        width: calc(100% - 20px);
        margin-inline: 10px;
      }
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.badges {
  display: flex;
  align-items: center;
  color: #333;

  span {
    border-radius: 7px;
    margin-inline: 8px;
    background-color: #fff;
    @include display-flex(row, center, center);
    padding: 0.05em 0.4em;

    p {
      color: #176eb5 !important;
    }

    svg {
      margin-inline: 4px;
      width: 25px;
    }
  }
}