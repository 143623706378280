@import '../../../resources/style/mixins';
@import '../../../resources/style/variables';

.filterDropdownWrap {
    @include display-flex(row, flex-start, flex-start);

    @media screen and (max-width:767.98px) {
        flex-direction: column;
    }

    .filterDropdown {
        flex: 1;
        height: 65px;



        @media screen and (max-width:767.98px) {
            width: 100%;
            ;
        }

        &:first-of-type {
            button {
                background-image: url('../../../resources/assets/images/green-gradient-2@2x.png');
                background-position: center;
                background-size: cover;
                border-start-end-radius: 0;
                border-end-end-radius: 0;
                text-transform: capitalize;

                @media screen and (max-width:776.98px) {
                    border-radius: 0
                }


                svg {
                    path {
                        stroke: white
                    }
                }

            }
        }

        &:nth-of-type(2) {
            button {
                border-radius: 0;
            }
        }

        &:last-of-type {

            button {
                border-start-start-radius: 0;
                border-end-start-radius: 0;

                border-inline-start: 1px solid $gray_30;

                @media screen and (max-width:776.98px) {
                    border-radius: 0
                }
            }
        }


        button {
            width: 100%;
            @include display-flex(row, space-between, center);
            background-color: white;
            border: 0;
            height: 100%;

            @media screen and (max-width:767.98px) {
                height: 80px;
                ;
            }

            &:focus {
                box-shadow: none !important;
            }

            &:active {
                background-color: white !important;
                border-color: white !important;
            }

            &:after {
                display: none
            }

            >div {
                width: calc(100% - 15px);
                text-align: start;

            }

            +div {
                width: 100%;
                background-color: white;
                color: $black_10;
                text-align: start;
            }

            svg {
                transform: rotate(90deg);
                width: 12px;
                height: 12px;
            }

            h3 {
                width: calc(100% - 20px);
                text-align: start;
            }
        }
    }

}