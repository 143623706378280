.tab {
  display: flex;
  flex-direction: row;

  @media screen and (max-width:991.98px) {
    flex-direction: column;
  }
}

ul.pointed_list {
  li {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    position: relative;
    margin-inline-start: 20px;

    @media screen and (max-width:991.98px) {
      margin-top: 1rem;
    }

    &:before {
      position: absolute;
      content: '';
      display: flex;
      margin-inline: 12px;
      padding: 5px;
      background-image: url('../../../resources/assets/icons/check-mark.svg');
      width: 15px;
      height: 15px;
      inset-inline-start: -30px;
      background-repeat: no-repeat;
      background-size: contain;

      inset-block-start: 50%;
      transform: translateY(-50%);



      @media screen and (max-width:991.98px) {
        margin-inline: 8px;
      }
    }
  }


}