@import '../../resources/style/mixins';
@import '../../resources/style/variables';

.card {
    border-radius: 25px;
    background-color: $gray-6;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 13px 16px #3B3B3B28;
    cursor: pointer;
    display: inline-block;
    width: 100%;

    border: 0;

    >div {
        &:first-of-type {
            justify-content: space-between;
            margin: 0;

            .card_top {
                padding: 1.5rem;

                h1 {
                    width: 75%;

                }

                p {
                    width: 86%;
                }
            }

            div {
                padding: 0px;

                &:first-of-type {
                    width: 140px;
                    // border-radius: 15px;
                    overflow: hidden;

                    img {
                        object-fit: cover;
                    }
                }

                &:last-of-type {
                    width: calc(100% - 150px);

                }
            }

        }

        &:last-of-type {
            &.resource_content {
                justify-content: space-between;
                background: transparent linear-gradient(90deg, #176EB5 0%, #16507E 100%) 0% 0% no-repeat padding-box;
            }

            background-color: $gray-5;
            padding-inline: 1.5rem;
            height: 65px;
            @include display-flex(row, flex-start, center);
            width: 100%;

            a {
                height: 45px !important;
            }
        }
    }
}


.custom_overlay {

    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.relative {
    position: relative
}

.play {
    background-color: rgba(255, 255, 255, 0.5);
    width: 75px !important;
    height: 75px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;
    border-radius: 50px;
    z-index: 1;
    @include display-flex(row, center, center);

    svg {
        fill: #ffff;
        width: 30px;
        height: 30px;

    }
}


.download {
    background-color: white;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 10px;
    height: 40px;

}