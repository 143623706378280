@import "../../resources/style/mixins";
@import "../../resources/style/variables";

.card {
	border-radius: 25px;
	background-color: $gray-6;
	overflow: hidden;
	position: relative;
	box-shadow: 0px 13px 16px #3b3b3b28;
	cursor: pointer;
	display: inline-block;
	width: 100%;
	// min-height: 21rem;
	border: 0;

	>div {
		&:first-of-type {
			justify-content: space-between;
			margin: 0;

			.card_top {
				padding: 1rem;

				@media screen and (max-width: 575.98px) {
					padding: 0.8rem;
				}

				p {
					width: 86%;
				}
			}

			div {
				padding: 0px;
			}
		}

		&:last-of-type {
			&.resource_content {
				justify-content: space-between;
				background: transparent linear-gradient(90deg, #176eb5 0%, #16507e 100%) 0% 0% no-repeat padding-box;
			}

			background-color: $gray-5;
			padding-inline: 1rem;
			height: 65px;
			@include display-flex(row, flex-start, center);
			width: 100%;

			@media screen and (max-width: 575.98px) {
				padding: 0.8rem;
			}

			@media screen and (max-width: 767.98px) {
				height: 55px;
			}

			a {
				height: 40px !important;

				@media screen and (max-width: 767.98px) {
					height: 35px !important;
				}
			}
		}
	}
}

video {
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.custom_overlay {
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.relative {
	position: relative;
}

.play {
	background-color: rgba(255, 255, 255, 0.5);
	width: 65px;
	height: 65px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 38%;
	border-radius: 50px;
	z-index: 1;
	@include display-flex(row, center, center);

	@media screen and (max-width: 991.98) {
		width: 40px !important;
		height: 40px;
	}

	svg {
		fill: #ffff;
		width: 30px;
		height: 30px;
	}
}

.download {
	background-color: white;
	border-radius: 7px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-inline: 10px;
	height: 35px;
}

.card_media {
	width: 160px;
	border-radius: 15px;
	overflow: hidden;

	@media screen and (max-width: 991.98px) {
		width: 100px;
	}

	img {
		object-fit: cover;
		border-radius: 15px;
	}
}

.card_text {
	width: calc(100% - 170px);

	@media screen and (max-width: 991.98px) {
		width: calc(100% - 110px);
	}
}