@import "../../resources/style/variables";
@import "../../resources/style/mixins";

.btn {
  width: fit-content;
  border-radius: 6px;
  box-shadow: 0px 3px 6px $gray_10;
  overflow: hidden;
  @include display-flex(row, space-between, center);
  height: 50px;

  &_white {
    background-color: white;
    padding: 10px;
  }

  &_blue {
    background-color: $blue_50;
    color: white !important;
    width: fit-content;


  }

  .btn_text {
    @include display-flex(row, center, center);
    height: 100%;
    color: $blue_100;
    width: 100%;

    &_custom {
      width: fit-content;
      color: white;
    }

    &_content {
      width: fit-content;
      padding-inline: 20px;
    }
  }

  .btn_arrow {
    background-color: $blue_60;
    width: 50px;
    height: 100%;
    @include display-flex(row, center, center);
  }
}