@import '../../../resources/style/variables';
@import '../../../resources/style/mixins';

.header {
  background-color: $gray_5;
  display: flex;
  align-items: center;
  color: #0B1519;
  padding: 1rem;

  .left {
    @include display-flex(row, flex-start, center);
    width: 85%;
    margin-inline-start: auto;

    @media screen and (max-width:991.98px) {
      width: 100%;
    }



    img {
      width: 70px;
      height: 70px;
      margin-inline-end: 10px;

      @media screen and (max-width:991.98px) {
        display: none
      }

      @media screen and (max-width:991.98px) {
        display: none;
      }
    }

    >.text {
      width: 75%;

      @media screen and (max-width:767.98px) {
        width: 100%;
      }
    }

  }

  @media screen and (max-width:991.98px) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    height: auto;
    padding-block: 1rem;
    padding-inline: 1rem;
  }

}

.content {
  padding: 0 50px;
  @include display-flex(row, space-between, center);


  @media screen and (max-width:991.98px) {
    padding: 0 1rem;
  }

  .left {
    width: 15%;
    position: relative;

    @media screen and (max-width:797.98px) {
      display: none
    }

    img {
      width: 215%;
      position: absolute;
      inset-inline-start: -90%;

    }
  }

  .right {
    width: 85%;

    @media screen and (max-width:797.98px) {
      width: 100%;
    }

    a {
      background-color: #0593FF;
      border-radius: 5px;
      color: white;


      p {
        color: white
      }

    }
  }


  .column1 {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    align-items: center;
    margin: 2rem 0rem;

    >div {
      @media screen and (max-width:991.98px) {
        position: absolute;
        bottom: 10px;
        width: 90%;

        a {
          width: 100%;

          @media screen and (max-width:991.98px) {
            width: 70%;
          }
        }

      }
    }


  }

}

.btnText {
  color: #176eb5;
}

.list_ar {
  border-right: 1px solid white;
}

.list_en {
  border-left: 1px solid white
}