@import '../../resources/style/variables';
@import '../../resources/style/mixins';

.modal_header {
  display: flex;
  flex-direction: column;
  background-color: $gray-6;
  padding-block: 1.5rem;

  .modal_title {
    display: flex;
    align-items: center;
    width: 100%;
    padding-inline: 16px;



    svg {
      &:nth-of-type(1) {
        margin-right: 22px;
      }

      &:nth-of-type(2) {
        margin-left: auto;

      }
    }
  }
}

.secTitle {
  background-color: #c5c5c2;
  min-height: 40px;
  width: 100%;
  padding-inline: 55px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    cursor: pointer;

    &:nth-of-type(1) {
      margin-right: 20px;
    }
  }
}

.circle {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background-color: $gray_50;

  @include display-flex(row, center, center);

  svg {
    margin: 0 !important;

  }

}
.white_bg{
  background-color: white;
}

.absolute{
    position: absolute;
    top:0.5rem;
    &_en{
      right:16px;
    }
    &_ar{
      left:16px;
    }

}

