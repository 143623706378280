@import "../../resources/style/variables";

.hero_section {
    background-image: url('../../resources/assets/images/blue-patten-bg.png');
    height: 300px;
    display: flex;
    align-items: flex-end;

    h1 {
        color: white;
        margin-block-end: 2rem;
    }
}

.channel_tabs {

    .nav-tabs {
        border: 0 !important;
        background-color: $gray_5;
        padding-inline: 5rem !important;

        @media screen and (max-width: 767.98px) {
            padding-inline: 1.5rem !important;
        }

        @media screen and (min-width: 768px) and (max-width: 991.98px) {
            padding-inline: 2rem !important;
        }

        @media screen and(min-width:992px) and (max-width: 1440.98px) {
            padding-inline: 3rem !important;
        }



        .nav-link {
            color: #000 !important;
            font-weight: 600;
            margin-bottom: 0;
            border: 0;


            &:hover {
                border: 0;
                isolation: unset;
            }

            &.active {
                background-color: transparent !important;
                border-bottom: 2px solid $blue_100;
                color: $blue_100  !important;
            }
        }

    }

    .video-wrapper {
        h2 {
            margin-block-start: 3rem;
            margin-block-end: 2rem;
            text-transform: capitalize;
        }
    }

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            display: flex;
            // justify-content: center;
            // align-items: center;
        }
    }

}