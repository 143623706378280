.bg_white {
  background-color: white;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div:first-child {
    align-self: center;
    padding-inline: 2rem;
  }
  @media screen and (max-width:991.98px){
    padding-block:2rem;
  }
}

.items {
  padding: 0;
  li {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;

    svg {
      width: 20px;
      height: 20px;
      margin-top: 5px;
    }

    p {
      width: calc(100% - 30px);
    }
  }
}
.googleIcon {
  height: 100%;
  @media screen and (max-width:991.98px){
    display:none;
  }
}
