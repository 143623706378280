@import '../../../resources/style/variables';

.program-levels {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../../resources/assets/images/blue-gradient.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-position: 100% -120px;
  transform: translateY(-92px);
  padding-block-start: 15rem;
  background-color: $gray-6;


  h1 {
    margin-bottom: 3rem;
    color: white
  }
}



.main_navContainer {
  position: relative;
  border-radius: 25px;
  // overflow: hidden;
  width: 70%;

  @media screen and (max-width:991.98px) {
    flex-direction: column !important;
    width: 100%;
  }

  @media screen and (min-width:992px) and (max-width:1036.98px) {
    width: 100%;
  }

  @media screen and (min-width:1037px) and (max-width:1199.98px) {
    width: 90%;
  }
}

.link_card {
  background-color: #176EB5;
  height: 75px;
  width: calc(100% / 3 - 1rem);
  margin-inline: 0.5rem;
  align-items: center;
  display: flex;
  border-radius: 15px;

  @media screen and (max-width:575.98px) {
    width: 100%;
    margin-bottom: 8px;
  }

  @media screen and (min-width:576px) and (max-width:797.98px) {
    width: calc(100% / 2 - 1rem)
  }

}

.link_wrapper {
  display: flex;
  margin-top: 2.5rem;
  width: 100%;

  @media screen and (max-width:575.98px) {
    flex-direction: column;
  }
}

.shape {
  position: absolute;
  width: 150px;
  height: 150px;
  z-index: 1;

  &:nth-of-type(2) {
    left: -15px;
    bottom: 0;
  }

  &:nth-of-type(1) {
    right: -20px;
    top: -60px
  }

  &:nth-of-type(3) {
    right: -20px;
    top: 25px;
    z-index: -1;
  }

  @media screen and (max-width:991.98px) {
    display: none;
  }
}



html[lang="ar"] {
  .shape {
    &:nth-of-type(2) {
      right: -50px;
      left: auto;
      bottom: -15px;
    }

    &:nth-of-type(1) {
      left: -40px;
      right: auto;
      top: -60px
    }

    &:nth-of-type(3) {
      left: -100px;
      right: auto;
      top: 25px;

      @media screen and (min-width:992px) and (max-width:1199.98px) {
        left: -75px;
      }
    }
  }


}