@import "../../resources/style/variables";
@import "../../resources/style/mixins";

footer {
  background: transparent linear-gradient(92deg, $blue_100 0%, $blue_120 100%) 0% 0% no-repeat padding-box;
  color: white;

  +div {
    @include display-flex(row, center, center);
    padding: 6px;
  }

  >div {
    >ul {
      @include display-flex(column, flex-start, flex-start);

      li {
        &:not(:first-of-type) {
          width: 80%;
          margin-right: 10px;
        }
      }
    }
  }

  .list {
    &--logos {
      @include display-flex(row-reverse, flex-end, center);

      li {
        margin-right: 12px;
        width: 100px;

        &:not(:first-of-type) {
          width: 90px;
        }

        img {
          object-fit: cover;
        }
      }
    }

    &_item {
      color: #fff;
      margin-bottom: 15px;

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
  }

  .border {
    border-right: 1px solid $gray-6;


    @media screen and (max-width:767.98px){
      border:0
    }
  }
}

.ul {
  padding-right: 0;
  @media screen and (max-width:991.98px){
    margin-bottom:3.5rem;
  }
}

.m_bottom{
  margin-bottom:3.5rem;
  @media screen and (max-width:991.98px){
    margin-bottom: 2rem;
  }
}

