@import '../../../resources/style/variables';
@import '../../../resources/style/mixins';

.footer {

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: $gray_6;

  .footer_text {

    text-align: center;
    margin-bottom: 3.5rem;

    @include display-flex(column, center, center);

    h1 {
      -webkit-background-clip: text;
      background-clip: text;
      background-image: url('../../../resources/assets/images/purble-gradient.png');
      color: transparent;

    }

    p {
      width: 60%;

      @media screen and (max-width:991.98px) {
        width: 85%;
      }
    }

  }

}

.transparent {
  background-color: transparent;
}