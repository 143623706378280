@import "../../../resources/style/variables";

.input-item {
  width: 95%;
  margin: 0px auto;
  padding-block: 0px;
  padding-inline: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;

  @media only screen and (max-width: 991.9px) {
    padding-inline: 10px;
  }

  input {
    height: 23px;
    width: 23px;
    accent-color: $blue_100;
    border: 1px solid $gray_100;
  }

  label {
    margin-inline: 15px;
    margin-bottom: 0;
    width:calc(100% - 25px)
  }
}
