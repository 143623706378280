@import '../../resources/style/variables';
@import '../../resources/style/mixins';



.card {
    border-radius: 15px;
    @include display-flex(row, space-between, center);
    position: relative;
    overflow: hidden;
    color: inherit !important;
    width: fit-content;
    border: 0;
    background-color: $gray-5;
    width: 100%;
    pointer-events: none;
    z-index: 1;


    &_custom {
        width: calc(100% /3 - 1rem);
        cursor: pointer;
        pointer-events: all;

        @media screen and (max-width:575.98px) {
            width: 100%;
            margin-bottom: 10px;

        }

        @media screen and (min-width:576px) and (max-width:767.98px) {
            width: calc(100% /2 - 1rem);
            margin-bottom: 10px;

        }

        &:hover {
            background-color: inherit important;
            color: white !important;

            .videoIcon {
                visibility: visible;
                opacity: 1;
            }


            .absolute {
                height: 100%;
                z-index: -1
            }

            .circle {
                background-color: white;
            }
        }
    }

    &_activity {
        width: calc(100% / 3 - 2rem);

        .circle {
            display: none
        }
    }


    &_media {
        border-radius: 50%;
        background-color: $gray_50;
        width: 80px;
        height: 80px;
    }

    &_text {
        width: calc(100% - 90px);
        flex-direction: column !important;
        justify-content: flex-start;
        align-items: flex-start !important;
        margin-inline: 10px;

        @media screen and (max-width:991.98px) {
            width: 100%;
            ;
            margin-inline: 0
        }


        h2 {
            margin-bottom: 0;
        }

    }

    .grayBg {

        @include display-flex(row, flex-start, center);

        @media screen and (max-width:767.98px) {
            height: auto;
            padding-top: 20px;
        }

        &_custom {
            background-color: $gray-6;

            @media screen and (max-width:767.98px) {
                height: 70px;
                padding-top: 20px;
            }
        }

        .circle {
            width: 80px;
            height: 80px;
            border-radius: 50px;
            background-color: $gray_50;
            @include display-flex(row, center, center);

            @media screen and (max-width:991.98px) {
                display: none !important;
            }

            @media screen and (min-width:992px) and (max-width:1199.98px) {
                width: 70px;
                height: 70px;
            }
        }

    }

    &_wrapper {
        min-height: 12rem;
        height: fit-content;

        @media screen and (max-width:767.98px) {
            min-height: 9rem;
        }
    }


}

.absolute {
    height: 18px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    transition: height 0.2s ease-in-out;
}

.border_1 {
    background-color: #3F9CBB;
}

.border_2 {
    background-color: #9995C7;

}

.border_3 {
    background-color: #74BFE9;

}

.border_4 {
    background-color: #FAA880
}

.border_5 {
    background-color: #E5B65E
}

.border_6 {
    background-color: #03ca8e
}

.border_7 {
    background-color: #BCD85E
}

.border_8 {
    background-color: #DCA5CC;
}


.absoluteBg {
    background-image: url('../../resources/assets/images/bg-modal-image.png');
    background-position: center;
    background-repeat: no-repeat;
    height: 100px;
    @include display-flex(row, flex-start, center);

    .p {
        color: white
    }

}

.listItems {
    text-align: left;
    margin-bottom: 0;
    height: 100%;
    @include display-flex(column, center, flex-start);
    padding-block: 2rem;


    li {
        list-style-type: disc;
        margin-inline: 1rem;

        &:not(:last-of-type) {
            margin-bottom: 8px;
        }


    }

}


.cards_wrapper {
    @include display-flex(row, space-between, flex-start);

    @media screen and (max-width:767.98px) {
        flex-direction: column
    }
}

.pad {
    padding-inline: 3rem;

    @media screen and (max-width:1024px) {
        padding-inline: 1rem;
    }
}

.videoIcon {
    position: absolute;
    inset-block-start: 30px;
    inset-inline-end: 15px;
    visibility: hidden;
    opacity: 0;
}