@import '../../../resources/style/mixins';
@import '../../../resources/style/variables';

.circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px solid rgba(33, 213, 38, 27%);
    background-color: white;
    @include display-flex(column, center, center);
    text-align: center;
    padding: 0.5rem;
    margin-inline: 1.5rem;
    position: relative;

    @media screen and (max-width:767.98px) {
        width: calc(100% / 2 - 1rem);
    }

    @media screen and (max-width:991.98px) {
        border-radius: 0;
        margin-inline: 0.5rem;
        margin-block: 0.3rem;


    }

    &:not(.first) {
        box-shadow: 0 .5rem 1rem rgba(199, 209, 178, .48) !important;
    }

    &.first {
        background-image: url('../../../resources/assets/images/green-gradient-3@2x.png');
        background-size: cover;


        color: white;

        h3 {
            color: white
        }
    }



    &:not(.last) {
        &:after {
            position: absolute;
            width: 180px;
            height: 8px;
            content: '';
            background-color: #0593FF;
            inset-block-start: 50%;
            inset-inline-end: -90px;
            z-index: -1;

            @media screen and (max-width:1199.98px) {
                display: none;
            }
        }
    }


    h3 {
        color: $green_30
    }

}