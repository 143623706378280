.landing {
  background-image: url('../../../resources/assets/images/putble-curve-bg.png');
  background-position: 100% -110px;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  background-repeat: no-repeat;
  background-color: white;

  @media screen and (max-width:767.98px) {
    padding-block-start: 5rem;
  }

  @media screen and (max-width:1199.98px) {
    background-position: 100%;
  }

  .left {
    color: white;
    align-self: center;
    width: 50%;
    margin-top: -6rem;

    @media screen and (max-width:1199.98px) {
      width: 100%;
    }


    p {
      margin-block-start: 2rem;
      margin-block-end: 3rem !important;
    }
  }

  .right {
    @media screen and (max-width:991.98px) {
      display: none;
    }

    width: 45%;
    margin-inline-start: auto;
    position: relative;

    img {
      object-fit: contain;
      inset-inline-end: -40px;
      inset-block-start: -40px;
      position: absolute;
      width: 100%;

      &:lang(ar) {
        transform: scaleX(-1);
      }

      @media screen and (min-width:992px) and (max-width:1199.98px) {
        width: 120%
      }
    }

  }

}