@import '../../resources/style/variables';
@import '../../resources/style/mixins';

.accordion-item {
    margin-bottom: 20px;
    border: 0 !important;



    &:last-of-type {
        margin-bottom: 100px;
        border-radius: 17px;
    }

    .header {
        overflow: hidden;

        // button {
        border-radius: 25px;
        border: 3px solid $gray_50;
        background-image: url("../../resources/assets/images/gray-long-cta-bg.png");
        background-size: cover;

        @media screen and (max-width:991.98px) {
            border-radius: 15px;
        }

        // }
        button {
            background-color: transparent;
            box-shadow: none;
            padding-inline: 3rem !important;

            @media screen and (max-width:991.98px) {
                padding: 0.5rem 1rem !important;

                svg {
                    display: none
                }
            }

            &:after {
                background-color: $gray_100;
                border-radius: 50px;
                width: 30px;
                height: 30px;
                @include display-flex(row, center, center);
                background-position: center;
                margin-right: 0
            }
        }



        h3,
        span {
            color: $blue_100;
            margin-bottom: 0;
        }

        span {
            display: block;
            margin-bottom: 4px;
        }
    }

    .body {

        >p {
            margin-left: 0;
            width: 70%;

            @media screen and (max-width:991.98px) {
                width: 100%
            }
        }

        h4 {
            margin: 3rem 0 2rem 0;
        }

    }
}

.learn_more {
    border-radius: 25px;
    height: 18rem;
    margin-left: -20px;
    margin-right: -20px;
    background-image: url("../../resources/assets/images/blue-patten-bg.png");

    h4 {
        color: #fff;
    }
}